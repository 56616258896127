import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2560.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">

<path d="M12530 14282 l0 -1559 -67 -26 c-38 -15 -102 -40 -143 -56 l-75 -29
-3 1249 -2 1249 -400 0 -400 0 0 -1389 0 -1390 -135 -44 -135 -44 -2 941 -3
941 -355 0 -355 0 -1 -1005 -2 -1005 -50 -9 c-29 -5 -97 -5 -159 0 l-108 9 -3
733 -2 732 -330 0 -330 0 -2 -527 c-4 -840 -6 -775 26 -878 92 -290 277 -516
517 -629 31 -14 59 -26 63 -26 5 0 22 -6 39 -14 162 -74 558 -134 817 -123
189 8 393 24 452 36 29 6 114 20 188 31 334 52 823 184 1220 330 47 17 105 38
130 47 57 20 435 169 475 188 17 7 84 37 150 65 333 143 890 420 1131 563 96
57 104 57 139 -4 17 -29 37 -64 45 -78 9 -14 31 -51 48 -81 18 -30 37 -62 42
-70 36 -58 90 -151 90 -155 0 -3 15 -28 33 -57 19 -29 76 -126 126 -215 51
-90 97 -161 101 -158 4 3 43 94 85 202 42 109 87 223 100 253 23 54 364 909
549 1377 53 133 107 268 122 300 l25 58 -22 115 c-12 63 -24 118 -28 121 -3 4
-96 -4 -206 -16 -110 -13 -245 -28 -300 -34 -55 -7 -129 -16 -165 -22 -36 -5
-110 -14 -165 -19 -110 -10 -239 -24 -635 -70 -146 -17 -299 -35 -340 -40 -41
-5 -178 -20 -305 -35 -126 -14 -254 -29 -283 -32 -42 -5 -51 -9 -48 -22 3 -9
14 -60 25 -115 23 -111 6 -87 241 -329 63 -65 116 -122 118 -125 3 -10 -60
-41 -308 -154 -25 -11 -74 -34 -110 -50 -36 -17 -117 -53 -180 -80 -63 -27
-134 -58 -157 -69 -24 -10 -47 -19 -53 -19 -7 0 -10 467 -10 1410 l0 1410
-360 0 -360 0 0 -1558z"/>
<path d="M5305 10921 c-127 -22 -246 -157 -275 -310 -5 -29 -10 -135 -10 -233
0 -164 2 -182 20 -208 38 -53 115 -48 145 9 12 24 15 68 15 218 0 204 7 246
47 294 51 60 41 59 635 59 l543 0 30 24 c24 19 30 31 30 63 0 46 -20 70 -67
84 -37 10 -1051 10 -1113 0z"/>
<path d="M7255 10921 c-84 -21 -179 -102 -224 -189 -28 -55 -51 -146 -51 -202
0 -115 69 -264 153 -331 85 -68 97 -69 587 -69 483 0 502 2 582 63 97 74 148
177 156 313 10 174 -77 333 -216 397 -46 22 -57 22 -502 24 -250 1 -468 -2
-485 -6z m928 -188 c43 -22 85 -71 97 -113 5 -19 10 -63 10 -98 0 -97 -32
-156 -102 -193 -35 -18 -63 -19 -470 -19 -421 0 -434 1 -467 21 -49 29 -69 57
-87 118 -19 62 -14 153 10 199 15 29 59 73 91 90 14 8 157 11 453 12 384 0
437 -2 465 -17z"/>
<path d="M9468 10914 c-56 -30 -64 -90 -19 -135 l29 -29 311 0 c171 0 311 -2
311 -4 0 -2 -10 -26 -22 -53 -20 -44 -22 -67 -26 -266 -2 -121 -1 -229 2 -241
6 -24 54 -56 84 -56 12 0 34 11 49 24 l28 24 5 214 c6 247 16 281 92 331 l42
27 211 0 c189 0 213 2 241 19 56 34 52 116 -7 146 -42 22 -1291 21 -1331 -1z"/>
<path d="M11034 10916 c-42 -18 -61 -65 -44 -107 26 -62 -8 -60 733 -57 641 3
676 4 695 22 48 43 31 124 -29 145 -50 17 -1314 14 -1355 -3z"/>
<path d="M12633 10915 c-50 -22 -55 -55 -51 -345 3 -286 6 -298 79 -367 75
-71 41 -68 684 -71 528 -3 583 -2 612 14 51 27 66 92 29 129 -33 33 -55 34
-616 35 -488 0 -567 2 -587 15 l-23 15 0 256 c0 278 -5 305 -56 324 -31 12
-32 12 -71 -5z"/>
<path d="M14210 10917 c-64 -22 -77 -104 -23 -147 25 -20 39 -20 698 -20 665
0 673 0 699 21 21 16 26 29 26 62 0 50 -6 61 -45 81 -26 13 -115 15 -675 15
-475 0 -654 -3 -680 -12z"/>
<path d="M16025 10921 c-58 -15 -113 -51 -164 -108 -82 -92 -119 -219 -100
-351 18 -126 100 -255 194 -305 40 -22 47 -22 621 -25 368 -2 590 1 607 7 31
12 61 65 53 95 -3 12 -15 33 -27 47 l-21 24 -556 3 c-307 1 -567 4 -579 7 -30
7 -90 61 -111 99 -11 22 -16 55 -16 111 0 95 20 143 77 189 l39 31 571 5 c315
3 578 9 586 14 7 5 20 24 27 43 13 31 13 38 -3 71 -11 22 -28 39 -43 43 -34 9
-1117 9 -1155 0z"/>
<path d="M17645 10916 c-82 -26 -138 -71 -190 -151 -44 -69 -65 -143 -65 -235
0 -167 79 -308 207 -372 l48 -23 480 0 c478 0 480 0 525 23 278 138 281 593 5
740 l-50 27 -460 2 c-349 1 -470 -1 -500 -11z m945 -186 c51 -26 87 -80 101
-153 22 -109 -29 -224 -114 -257 -40 -14 -864 -14 -903 0 -39 15 -72 47 -97
95 -29 54 -29 161 0 226 21 46 70 93 108 102 11 2 210 5 443 6 406 1 424 0
462 -19z"/>
<path d="M19136 10920 c-16 -5 -40 -19 -53 -32 -52 -48 -53 -57 -57 -375 -2
-174 1 -310 7 -326 19 -56 102 -71 143 -25 17 18 19 43 22 274 2 140 7 254 12
254 8 0 141 -149 371 -415 53 -61 109 -116 125 -124 55 -25 125 -24 168 1 24
14 130 125 264 278 123 140 229 255 235 255 9 0 13 -65 15 -240 1 -132 4 -249
7 -260 5 -24 54 -55 85 -55 12 0 35 13 51 29 l29 29 0 303 c0 288 -1 305 -21
346 -34 71 -107 103 -182 81 -48 -15 -89 -55 -327 -331 -190 -219 -225 -252
-254 -241 -14 5 -85 82 -212 229 -37 44 -71 82 -74 85 -6 6 -62 70 -143 162
-76 87 -144 119 -211 98z"/>
<path d="M5390 10613 c-66 -25 -81 -103 -29 -147 l31 -26 488 0 c330 0 497 4
513 11 33 15 59 62 51 93 -3 13 -17 35 -31 48 l-25 23 -491 2 c-271 1 -499 -1
-507 -4z"/>
<path d="M11050 10611 c-64 -16 -65 -18 -65 -163 0 -161 9 -190 78 -256 66
-62 54 -61 709 -61 366 -1 607 3 622 9 15 5 34 25 43 44 14 30 14 39 3 67 -24
58 -19 57 -663 58 -323 1 -594 4 -602 7 -11 5 -15 22 -15 65 l0 59 588 0 c566
0 588 1 619 20 59 36 55 111 -7 142 -32 17 -84 18 -660 17 -344 -1 -636 -4
-650 -8z"/>
<path d="M14212 10610 c-19 -4 -39 -13 -45 -19 -17 -17 -21 -207 -7 -275 9
-42 22 -67 56 -105 75 -84 51 -81 734 -81 543 0 598 1 620 17 34 23 40 33 40
71 0 46 -14 68 -52 81 -22 8 -206 11 -613 11 -319 -1 -588 2 -598 5 -13 6 -17
19 -17 66 l0 59 594 0 c577 0 594 1 619 20 31 25 42 65 26 102 -24 59 -9 58
-696 57 -345 -1 -643 -5 -661 -9z"/>
<path d="M6660 10301 c-36 -36 -46 -72 -32 -120 14 -45 55 -71 112 -71 76 0
128 83 96 152 -34 71 -125 91 -176 39z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
